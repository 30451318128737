import logger from '../../logger/logger';

const log = logger.scoped('chat');

const sleep = (m: number) => new Promise((r) => setTimeout(r, m));

type DelayFunc = (attempts: number, baseDelay: number) => number;

function noDelay(_attempts = 0, _baseDelay = 0): number {
  return 0;
}

function exponentialDelay(attempts = 0, baseDelay = 100): number {
  const delay = Math.pow(2, attempts) * baseDelay;
  const randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay
  return delay + randomSum;
}

function constantDelay(_attempts = 0, baseDelay = 1000): number {
  return baseDelay;
}

export const delayStrategies = {
  noDelay: noDelay,
  exponentialDelay: exponentialDelay,
  constantDelay: constantDelay,
};

interface RetryOptions {
  retries: number;
  retryDelay: number;
  retryDelayStrategy: DelayFunc;
  aborter?: AbortController;
}

const defaultRetryOptions: RetryOptions = {
  retries: 10,
  retryDelay: 1000,
  retryDelayStrategy: delayStrategies.noDelay,
};

export const retry = async (
  func: () => unknown,
  name: string,
  options: Partial<RetryOptions>
): Promise<unknown> => {
  const _options = { ...defaultRetryOptions, ...options };
  let attempts = 0;
  while (attempts < _options.retries) {
    if (_options.aborter?.signal.aborted) {
      throw new Error(`Aborted - ${name}`);
    }
    try {
      return await func();
    } catch (e) {
      log.error(`LunaPark Retry - ${name}, attempts: ${attempts}`, e);
      await sleep(_options.retryDelayStrategy(attempts, _options.retryDelay));
      attempts += 1;
    }
  }
  throw new Error(`Max retries exceeded - ${name}`);
};
