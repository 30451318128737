import 'emoji-mart/css/emoji-mart.css';

import { type BaseEmoji, Picker } from 'emoji-mart';
import React, { useEffect, useRef, useState } from 'react';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import { getAgentInfo } from '../../utils/user-agent';
import { ChatEmojiIcon } from '../icons/Chat/ChatEmojiIcon';
import { ChatSendIcon } from '../icons/Chat/ChatSendIcon';
import {
  ChannelType,
  ChatEmojiButtonDOMId,
  ChatMessageInputDOMId,
  ChatMode,
  ErrorMessageType,
  MaxMessageTextSize,
  MentionTrigger,
  type Recipient,
} from './common';
import {
  buildDisplayName,
  useChatMode,
  useChatService,
  useSetError,
} from './Context';
import { MessageTo, type MessageToStyle } from './MessageTo';

interface Position {
  bottom: number;
}

interface MessageInputProps {
  messageInputElRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  activeRecipient: Recipient | null;
  recipients: Recipient[];
  sendButtonDisabled: boolean;
  handleRecipientChange: (rid: string, autoFocus?: boolean) => void;
  handleSendMessage: () => void;
  handleToggleSendButton: (val: boolean) => void;
  handleAddMentionedRecipient: (recipient: Recipient) => void;
  handleToggleFadeoutMessage: (val: boolean) => void;
  messageToStyle: MessageToStyle;
}

export const MessageInput = ({
  messageInputElRef,
  activeRecipient,
  recipients,
  sendButtonDisabled,
  handleRecipientChange,
  handleSendMessage,
  handleToggleSendButton,
  handleAddMentionedRecipient,
  handleToggleFadeoutMessage,
  messageToStyle,
}: MessageInputProps): JSX.Element => {
  const chatService = useChatService();
  const mode = useChatMode();
  const [emojiPickerOpen, setEmojiPickerOpen] = useState<boolean>(false);
  const [mentionListOpen, setMentionListOpen] = useState<boolean>(false);
  const [mentionListPosition, setMentionListPosition] = useState<Position>({
    bottom: 0,
  });
  const [query, setQuery] = useState<string>('');
  const [mentionRecipients, setMentionRecipients] = useState<Recipient[]>([]);
  const [activeMentionIdx, setActiveMentionIdx] = useState<number>(-1);
  const [triggerIdx, setTriggerIdx] = useState<number>(-1);
  const pickerRef = useRef<HTMLDivElement | null>(null);
  const latestScrollHeightRef = useRef<number>(0);
  const emojiButtonRef = useRef<HTMLButtonElement | null>(null);
  const setError = useSetError();

  const autoResize = (el: HTMLTextAreaElement | null) => {
    if (!el) return;
    if (latestScrollHeightRef.current !== el.scrollHeight) {
      el.style.cssText = 'height: auto';
      let height = el.scrollHeight;
      const agentInfo = getAgentInfo();
      if (agentInfo.browser.isFirefox) {
        const style = getComputedStyle(el);
        height =
          height - parseInt(style.paddingTop) - parseInt(style.paddingBottom);
      }
      el.style.cssText = `height: ${height}px`;
      latestScrollHeightRef.current = el.scrollHeight;
    }
  };

  const messageLengthCheck = (el: HTMLTextAreaElement) => {
    if (el.value.length >= MaxMessageTextSize) {
      setError(ErrorMessageType.MaxMessageTextSizeError);
    } else {
      setError(ErrorMessageType.None);
    }
  };

  const sendButtonStatusCheck = (el: HTMLTextAreaElement) => {
    if (el.value.trim() === '') {
      handleToggleSendButton(true);
    } else {
      handleToggleSendButton(false);
    }
  };

  const triggerTypingIndicator = (_el: HTMLTextAreaElement) => {
    if (activeRecipient) {
      chatService.typing(activeRecipient.id);
    }
  };

  const triggerMentionSelector = (el: HTMLTextAreaElement) => {
    const positionIndex = el.selectionStart;
    const textBeforeCaret = el.value.slice(0, positionIndex);
    const _triggerIdx = textBeforeCaret.lastIndexOf(MentionTrigger);
    setTriggerIdx(_triggerIdx);
    if (_triggerIdx === -1) {
      setTriggerIdx(_triggerIdx);
      setQuery('');
      setMentionListOpen(false);
      return;
    }
    const query = textBeforeCaret.slice(_triggerIdx + 1);
    const height = parseFloat(
      getComputedStyle(el, null).height.replace('px', '')
    );
    setMentionListPosition({ bottom: height + 14 });
    setMentionListOpen(true);
    setQuery(query);
    setActiveMentionIdx(0);
  };

  const handleChange = (el: HTMLTextAreaElement) => {
    autoResize(el);
    messageLengthCheck(el);
    sendButtonStatusCheck(el);
    triggerTypingIndicator(el);
    triggerMentionSelector(el);
  };

  const selectMention = (e: React.KeyboardEvent) => {
    let keyCaught = false;
    switch (e.key) {
      case 'ArrowDown':
        setActiveMentionIdx(
          activeMentionIdx + 1 < mentionRecipients.length
            ? activeMentionIdx + 1
            : 0
        );
        keyCaught = true;
        break;
      case 'ArrowUp':
        setActiveMentionIdx(
          activeMentionIdx - 1 >= 0
            ? activeMentionIdx - 1
            : mentionRecipients.length - 1
        );
        keyCaught = true;
        break;
      case 'Enter':
      case 'Tab':
        handleMentionRecipientSelect(mentionRecipients[activeMentionIdx]);
        keyCaught = true;
        break;
      default:
        break;
    }
    if (keyCaught) {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (triggerIdx !== -1) {
      selectMention(e);
      return;
    }

    if (e.key === 'Enter') {
      if (e.shiftKey) {
        return;
      }
      handleSendMessage();
      autoResize(messageInputElRef.current);
      e.preventDefault();
    }
  };

  const handleSendClick = () => {
    handleSendMessage();
    autoResize(messageInputElRef.current);
  };

  const handleToggleEmojiPicker = () => {
    setEmojiPickerOpen(!emojiPickerOpen);
  };

  const insertAtCursor = (input: HTMLTextAreaElement, textToInsert: string) => {
    const value = input.value;
    const start = input.selectionStart || 0;
    const end = input.selectionEnd || 0;
    input.value = value.slice(0, start) + textToInsert + value.slice(end);
    input.selectionStart = input.selectionEnd = start + textToInsert.length;
    input.focus();
  };

  const handleAddEmoji = (emoji: BaseEmoji) => {
    if (!messageInputElRef.current) {
      return;
    }
    insertAtCursor(messageInputElRef.current, emoji.native);
    handleChange(messageInputElRef.current);
    setEmojiPickerOpen(false);
  };

  const handleMentionRecipientSelect = (mentionedRecipient: Recipient) => {
    const inputEl = messageInputElRef.current;
    if (!inputEl || !mentionedRecipient) return;
    const preMention = inputEl.value.substr(0, triggerIdx);
    const postMention = inputEl.value.substr(inputEl.selectionStart);
    inputEl.value = `${preMention}${inputEl.value[triggerIdx]}${mentionedRecipient.username} ${postMention}`;
    const caretPosition = inputEl.value.length - postMention.length;
    inputEl.setSelectionRange(caretPosition, caretPosition);
    setMentionListOpen(false);
    autoResize(inputEl);
    handleAddMentionedRecipient(mentionedRecipient);
    inputEl.focus();
  };

  useOutsideClick(
    pickerRef,
    () => {
      setEmojiPickerOpen(false);
    },
    emojiButtonRef
  );

  useEffect(() => {
    if (!mentionListOpen) {
      setTriggerIdx(-1);
    }
  }, [mentionListOpen]);

  useEffect(() => {
    const newMentionRecipients = [];
    for (const r of recipients) {
      if (r.type !== ChannelType.Private) continue;
      const q = query.toLowerCase();
      if (!q || r.username.toLowerCase().includes(q)) {
        newMentionRecipients.push(r);
      }
    }
    setMentionRecipients(newMentionRecipients);
    if (newMentionRecipients.length === 0) {
      setMentionListOpen(false);
    }
    setActiveMentionIdx(0);
  }, [recipients, query]);

  const handleOnFocus = () => {
    if (mode !== ChatMode.Preview) return;
    handleToggleFadeoutMessage(false);
    chatService.scheduleScrollToBottom(0);
  };

  const handleOnBlur = () => {
    if (mode !== ChatMode.Preview) return;
    handleToggleFadeoutMessage(true);
    // chatService.scheduleScrollToBottom(6000);
  };

  return (
    <div
      className={`flex flex-col px-2 pb-2 z-4 ${
        mode === ChatMode.Preview ? 'bg-lp-black-001' : ''
      } rounded-xl`}
    >
      <MessageTo
        activeRecipient={activeRecipient}
        recipients={recipients}
        handleRecipientChange={handleRecipientChange}
        style={messageToStyle}
      />
      <div
        className={`text-sms w-65 absolute ${
          mentionListOpen ? 'block' : 'hidden'
        }`}
        style={{
          bottom: `${mentionListPosition.bottom || 50}px`,
        }}
      >
        <ul className='list-none bg-black p-0 m-0 w-full max-h-50 overflow-x-hidden overflow-y-auto rounded-md scrollbar'>
          {mentionRecipients.map((r, idx) => {
            return (
              <li
                className={`cursor-pointer rounded-lg p-2 truncate ${
                  activeMentionIdx === idx
                    ? 'text-white bg-lp-black-003'
                    : 'text-[#dddddd]'
                }`}
                key={r.id}
                title={r.username || ''}
                onClick={() => handleMentionRecipientSelect(r)}
                onPointerEnter={() => {
                  setActiveMentionIdx(idx);
                }}
              >
                {buildDisplayName(r)}
              </li>
            );
          })}
        </ul>
      </div>
      <div className='flex flex-row w-full relative'>
        <textarea
          id={ChatMessageInputDOMId}
          ref={messageInputElRef}
          rows={1}
          placeholder='Type your message...'
          onChange={(e) => handleChange(e.target)}
          onKeyDown={handleKeyDown}
          spellCheck='false'
          maxLength={MaxMessageTextSize}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          className='w-full h-10 min-h-10 bg-black border border-secondary rounded-xl text-white resize-none outline-none text-sms pt-2.5 pb-2 pr-16 pl-2 overflow-y-hidden chat-input-box-shadow'
        />
        <div className='absolute right-1 bottom-1 flex items-center justify-center'>
          <button
            id={ChatEmojiButtonDOMId}
            onClick={handleToggleEmojiPicker}
            ref={emojiButtonRef}
            className='w-8.5 h-8 p-0 m-0 bg-none outline-none cursor-pointer flex items-center justify-center hover:bg-none hover:outline-none active:bg-none active:outline-none focus:bg-none focus:outline-none'
          >
            <ChatEmojiIcon />
          </button>
          <button
            data-testid='chat-send-btn'
            onClick={handleSendClick}
            disabled={sendButtonDisabled}
            className={`p-0 m-0 w-8.5 h-8 rounded-lg flex items-center justify-center outline-none disabled:bg-secondary disabled:text-icon-gray 
            ${
              !sendButtonDisabled
                ? 'bg-gradient-to-bl from-chat-btn-start to-chat-btn-end'
                : ''
            }`}
          >
            <ChatSendIcon />
          </button>
        </div>
        <div
          className={`transform origin-center scale-80 ${
            emojiPickerOpen ? 'block' : 'hidden'
          }`}
          ref={pickerRef}
        >
          {emojiPickerOpen && (
            <Picker
              theme='dark'
              title='Pick your emoji…'
              style={{ position: 'absolute', bottom: '50px', right: '0' }}
              emoji='point_up'
              native={true}
              onSelect={handleAddEmoji}
            />
          )}
        </div>
      </div>
    </div>
  );
};
